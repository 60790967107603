import { User } from '../profile/types';
import { MCellAlertData } from '../mCellAlert/types';
import { OverheadDoorSignal } from '../factoryAutomation/types';
import { MCellEmployee, Shift } from '../resourceTracking/types';
import {
  ManufacturingLocation,
  ManufacturingLocationTimeRangeShiftFilter,
  MCell,
} from '../manufacturingLocation/types';
import { DateTimeRepeat, DayOfMonth, DayOfWeek } from '../../utils/date.types';
import { FormatColor } from '../common/types';

export type CellBoardSummaryDto = {
  mCell: MCell;
  mCellId: string;
  shift: Shift;
  currentCrewing?: number;
  minimumCrewing?: number;
  optimumCrewing?: number;
  maximumCrewing?: number;
  productionActual?: number;
  productionTarget?: number;
  productionGoal?: number;
  productionVariance?: number;
  firstTimePassActualPercent?: number;
  firstTimePassGoalPercent?: number;
  productionCellColor: FormatColor;
  crewingCellColor: FormatColor;
  firstTimePassCellColor: FormatColor;
};

export type CellBoardSummaryFilter = {
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
  cellType: CellType;
  shift: Shift;
  includeEmpty: boolean;
};

export type CellBoardSummaryHistoryDto = CellBoardSummaryDto & {
  shiftDate: string;
};

export type CellBoardSummaryHistoryFilter = CellBoardSummaryFilter & {
  shiftDate: string | null;
};

export type CellBoardMessageFilter = {
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
};

export type CellBoardMetricReport = {
  shiftDate: Date;
  mCellCode: string;
  mCellDescription: string;
  shift: Shift;
  laborHourPerUnitActual: number;
  laborHourPerUnitGoal: number;
  deliveryActualPercent: number;
  deliveryGoalPercent: number;
  firstTimePassActual: number;
  firstTimePassGoal: number;
  productionActual: number;
  productionGoal: number;
  crewingActual: number;
  crewingOptimum: number;
  crewingMinimum: number;
  crewingMaximum: number;
};

export type CellBoardMetricReportFilter =
  ManufacturingLocationTimeRangeShiftFilter;

export const cellTypes = ['All', 'RollupCellsOnly', 'WorkCellsOnly'] as const;

export type CellType = (typeof cellTypes)[number];

export const quadrants = [
  'None',
  'Safety',
  'Quality',
  'Delivery',
  'Production',
] as const;

export type QuadrantType = (typeof quadrants)[number];

export type CellBoardEnabledMCellInfo = {
  mCell: MCell;
  mCellId: string;
  enabled: boolean;
};

export type CellBoardEnabledMCellUpdate = Omit<
  CellBoardEnabledMCellInfo,
  'mCell'
>;

export type CellBoardMessageDto = {
  id: string;
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
  quadrant: QuadrantType;
  startDate: string;
  endDate: string;
  subject: string;
  body: string;
  attachments: CellBoardMessageAttachmentDto[];
  shifts: CellBoardMessageShiftDto[];
  links: CellBoardMessageLinkDto[];
  createdBy: User;
  repeat: DateTimeRepeat;
};

export type CellBoardMessageDetailDto = {
  id: string;
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
  cellBoardMCellMessages: CellBoardMCellMessageDto[];
  quadrant: QuadrantType;
  startDate: string;
  endDate: string;
  subject: string;
  body: string;
  links: CellBoardMessageLinkDto[];
  attachments: CellBoardMessageAttachmentDto[];
  tasks: CellBoardTaskDto[];
  shifts: CellBoardMessageShiftDto[];
  repeat: DateTimeRepeat;
  repeatDaysOfWeek: DayOfWeek[];
  repeatMonthlyDays: DayOfMonth[];
  repeatDurationDays: number;
  monthlyInterval: number;
  createdBy: User;
};

export type CellBoardMessageAttachmentDto = {
  id: string;
  cellBoardMessageId: string;
  filename: string;
  url: string;
};

export type CellBoardMessageShiftDto = {
  id: string;
  shift: Shift;
  cellBoardMessageId: string;
};

export type CellBoardMCellMessageDetailDto = {
  id: string;
  startDate: string;
  endDate: string;
  cellBoardMessageId: string;
  cellBoardMessage: CellBoardMessageDto;
  mCell: MCell;
  mCellId: string;
  mCellTasks: CellBoardMCellTaskDto[];
  shift: Shift;
  readDate?: string;
};

export type CellBoardMCellMessageDto = {
  id: string;
  startDate: string;
  endDate: string;
  cellBoardMessageId: string;
  mCell: MCell;
  mCellId: string;
  shift: Shift;
  readDate?: string;
};

export type CellBoardMCellTaskDto = {
  id: string;
  mCellMessageId: string;
  task: CellBoardTaskDto;
  taskId: string;
  completedDate?: string;
  notes?: string;
};

export type CellBoardMCellTaskReport = {
  id: string;
  taskDescription: string;
  mCellId: string;
  shift: Shift;
  startDate?: string;
  endDate?: string;
  readDate?: string;
  completedDate?: string;
  notes: string;
};

export const cellBoardMCellTaskCompleteStatuses = [
  'All',
  'Complete',
  'Incomplete',
] as const;

export type CellBoardMCellTaskCompleteStatus =
  (typeof cellBoardMCellTaskCompleteStatuses)[number];

export const cellBoardMCellTaskDueStatuses = [
  'All',
  'Current',
  'PastDue',
  'Upcoming',
] as const;

export type CellBoardMCellTaskDueStatus =
  (typeof cellBoardMCellTaskDueStatuses)[number];

export type CellBoardMCellTaskFilter = ManufacturingLocation & {
  shifts: Shift[];
  completeStatus: CellBoardMCellTaskCompleteStatus;
  dueStatus: CellBoardMCellTaskDueStatus;
};

export type CellBoardMessageInfo = {
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
  quadrant: QuadrantType;
  startDate: string;
  endDate: string;
  subject: string;
  body: string;
  shifts: Shift[];
  repeat: DateTimeRepeat;
  repeatWeeklyDaysOfWeek?: DayOfWeek[];
  repeatMonthlyDays?: DayOfMonth[];
  repeatDurationDays: number;
  monthlyInterval: number;
  tasks: string[];
  links: CellBoardMessageLinkDto[];
};

export type CellBoardMessageCreate = CellBoardMessageInfo & {
  attachments: File[];
};

export type MCellMessageCountResult = {
  messageCount: number;
  messageThreshold: number;
};

export type CellBoardMessageLinkUpdateInfo = {
  id: string;
  title: string;
  url: string;
};

export type CellBoardMessageLinkAddInfo = {
  messageId: string;
  title: string;
  url: string;
};

export type CellBoardMessageLinkDto = {
  id: string;
  title: string;
  url: string;
};

export type CellBoardMessageLinkInfo = {
  title: string;
  url: string;
};

export type CellBoardTask = {
  id: string;
  description: string;
  messageId: string;
  positionIndex: number;
};

export type CellBoardTaskDto = {
  id: string;
  description: string;
  messageId: string;
  positionIndex: number;
};

export type CellBoardConfig = {
  id: string;
  mCellId: string;
  shift: Shift;
  conditionalFormatFirstTimePassCurrentGoalOffset: number;
  conditionalFormatFirstTimePassPreviousOffset: number;
  scanOffLineGroup: ScanOffLineGroup;
  scanOffLineType: ScanOffLineType;
  scanOffLineInstancesPeriodDays: number;
  firstTimePassHistoryPeriodDays: number;
  firstTimePassHistoryMovingAverageDays: number;
  deliveryHistoryPeriodDays: number;
  deliveryHistoryMovingAverageDays: number;
  conditionalFormatCurrentProductionTargetPercent: number;
  conditionalFormatPreviousProductionTargetPercent: number;
  laborHoursPerUnitHistoryPeriodDays: number;
  laborHoursPerUnitHistoryMovingAverageDays: number;
  embeddedAnalyticsUrl?: string;
  compactEmbeddedAnalyticsUrl?: string;
  qualityQuadrantDisplayOption: QualityQuadrantDisplayOption;
  slideshowInterval: number;
};

const QUALITY_QUADRANT_DISPLAY_OPTIONS = ['FTP', 'Embedded'] as const;
export type QualityQuadrantDisplayOption =
  (typeof QUALITY_QUADRANT_DISPLAY_OPTIONS)[number]; // 'FTP' | 'Embedded';

export type CellBoardConfigInfo = Partial<
  Omit<CellBoardConfig, 'id' | 'mCellId' | 'shift'>
>;
const SCAN_OFF_LINE_TYPES = ['Originated', 'Remade'] as const;
export type ScanOffLineType = (typeof SCAN_OFF_LINE_TYPES)[number]; //'Originated' | 'Remade';
const SCAN_OFF_LINE_GROUPS = ['Reason', 'Component'] as const;
export type ScanOffLineGroup = (typeof SCAN_OFF_LINE_GROUPS)[number]; //'Reason' | 'Component';

export type ScanOffLineComponent = {
  id: string;
  code: string;
  description: string;
  active?: boolean;
  createdDate: string;
  updatedDate: string;
};

export type ScanOffLineReason = {
  id: string;
  code: string;
  description: string;
  active?: boolean;
  createdDate: string;
  updatedDate: string;
};

export type CellBoardScanOffLineInstances = {
  id: string;
  mCellId: string;
  shift: string;
  shiftDate: string;
  scanOffLineType: ScanOffLineType;
  scanOffLineComponent: ScanOffLineComponent;
  scanOffLineComponentId: string;
  scanOffLineReason: ScanOffLineReason;
  scanOffLineReasonId: string;
  instances: number;
  createdDate: string;
  updatedDate: string;
};

export type CellBoardScanOfflineCurrentPartDto = {
  id: string;
  workOrderNumber: string;
  scannedBy: string;
  unitTrackingNumber: string;
  trackingNumberOnLine?: boolean;
  scannedOffLineProducingLocation: string;
  remakeProducingLocation: string;
  station: string;
  salesOrderNumber: string;
  component: string;
  salesOrderLine: string;
  creditReturnPosted: boolean;
  reason: string;
  rejectComment: string;
  comments: string;
  returnArea: string;
  scannedOffLineDate: string;
  dueBackOnLineDate: string;
};

type CellBoardFirstTimePassHistory = {
  id: string;
  shiftDate: string;
  mCellId: string;
  shift: Shift;
  actualPercent?: number;
  goalPercent?: number;
  movingAveragePercent?: number;
  insufficientDataForMovingAverage: boolean;
};

type CellBoardDeliveryHistory = {
  id: string;
  mCellId: string;
  shift: Shift;
  shiftDate: string;
  actualPercent?: number;
  goalPercent?: number;
  movingAveragePercent?: number;
  insufficientDataForMovingAverage: boolean;
};

export type CellBoardDeliveryDetailMetric = {
  id: string;
  producingLocationName: string;
  productionLineName: string;
  workCenterName: string;
  loading?: number;
  pastDue?: number;
  todayTotal?: number;
};

export type CellBoardDeliveryMetric = {
  id: string;
  mCellId: string;
  loadingSummary?: number;
  pastDueSummary?: number;
  todayTotalSummary?: number;
  detailMetrics: CellBoardDeliveryDetailMetric[];
  createdDate: string;
  updatedDate: string;
};

export type CellBoardProductionMetricDto = {
  id: string;
  mCellId: string;
  shift: Shift;
  previousActual?: number;
  previousGoal?: number;
  currentActual?: number;
  currentGoal?: number;
  currentTarget?: number;
  currentVariance: number;
  currentVarianceColor: FormatColor;
  createdDate: string;
  updatedDate: string;
};

type CellBoardLaborHoursPerUnitMetric = {
  id: string;
  mCellId: string;
  shift: Shift;
  previousActual?: number;
  previousGoal?: number;
  currentActual?: number;
  currentGoal?: number;
};

type CellBoardFirstTimePassMetric = {
  id: string;
  mCellId: string;
  shift: Shift;
  previousPercent?: number;
  previousGoalPercent?: number;
  currentPercent?: number;
  currentGoalPercent?: number;
  createdDate: string;
  updatedDate: string;
};

type CellBoardLaborHoursPerUnitHistory = {
  id: string;
  mCellId: string;
  shift: Shift;
  shiftDate: string;
  actual?: number;
  goal?: number;
  movingAverage?: number;
  insufficientDataForMovingAverage: boolean;
};

export type CellBoardRosterLaborType = 'Planned' | 'Transfer';
export type CellBoardRoster = {
  id: string;
  shift: Shift;
  mCellId: string;
  mCellEmployee: MCellEmployee;
  mCellEmployeeId: string;
  currentMCell?: MCell;
  isLoggedIn: boolean;
  isPlanned: boolean;
  isExperienced: boolean;
};

export type CellBoardRosterInventoryCenterConfig = {
  id?: string;
  inventoryCenterId: string;
  experiencedThresholdHours: number;
};

type CellBoardSafetyMetricDto = {
  id: string;
  shift: Shift;
  mCellId: string;
  daysSinceLastRecordable: number;
  updatedDate: string;
};

export type CellBoardSafetyTopic = {
  id: string;
  inventoryCenterId: string;
  filename: string;
  url: string;
};

export type CellBoardSafetyMessage = {
  id: string;
  manufacturingLocation: ManufacturingLocation;
  mCellId: string;
  shift: Shift;
  message?: string;
};

export type CellBoardSafetyMessageInfo = {
  manufacturingLocation: ManufacturingLocation;
  shifts: Shift[];
  message?: string;
};

export type CellBoardDefaultSafetyMessage = {
  id: string;
  inventoryCenterId: string;
  message: string;
};

export type CellBoardDepartmentBacklogMetric = {
  id: string;
  producingLocationName: string;
  producingLocationBacklog?: number;
};

type CellBoardBacklogMetric = {
  id: string;
  mCellId: string;
  departmentBacklogSummary?: number;
  departmentBacklog?: CellBoardDepartmentBacklogMetric[];
};

export type CellBoardData = {
  config?: CellBoardConfig;
  safetyMessage: string;
  deliveryHistory?: CellBoardDeliveryHistory[];
  currentCrewing: number;
  laborHoursPerUnitHistory?: CellBoardLaborHoursPerUnitHistory[];
  laborHoursPerUnitMetric?: CellBoardLaborHoursPerUnitMetric;
  firstTimePassHistory?: CellBoardFirstTimePassHistory[];
  firstTimePassMetric?: CellBoardFirstTimePassMetric;
  scanOffLineInstances?: CellBoardScanOffLineInstances[];
  scanOffLineCurrentParts?: CellBoardScanOfflineCurrentPartDto[];
  deliveryMetric?: CellBoardDeliveryMetric;
  productionMetric?: CellBoardProductionMetricDto;
  roster?: CellBoardRoster[];
  safetyTopic?: CellBoardSafetyTopic;
  safetyMetric?: CellBoardSafetyMetricDto;
  backlogMetric?: CellBoardBacklogMetric;
  mCellAlertData?: MCellAlertData;
  mCell: MCell;
  shift: Shift;
  overheadDoorSignal?: OverheadDoorSignal;
  productionCurrentColor: FormatColor;
  productionPreviousColor: FormatColor;
  firstTimePassCurrentColor: FormatColor;
  firstTimePassPreviousColor: FormatColor;
};

export type ProductionMetricInterval = {
  id: string;
  mCellId: string;
  mCell: MCell;
  shift: Shift;
  fromDate: string;
  toDate: string;
  actualTotal: number;
  targetTotal: number;
  varianceTotal: number;
  varianceTotalColor: FormatColor;
  actual: number;
  target: number;
  variance: number;
  varianceColor: FormatColor;
  note: string;
  noteBy?: User;
  noteDate?: string;
  action: string;
  actionBy?: User;
  actionDate?: string;
};

export type ProductionMetricIntervalFilter =
  ManufacturingLocationTimeRangeShiftFilter;
